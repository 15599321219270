@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
:root {
  --primary-bg-color: #161616;
  --secondary-bg-color: #2e2e2e;
  --input-bg-color: #1d1d1d;
  --btn-bg-color: #ffffff;
  --primary-font: 'Roboto Mono', monospace; }

* {
  box-sizing: border-box; }

html {
  background-color: var(--primary-bg-color); }

body {
  font-family: var(--primary-font);
  margin: 0; }

main {
  max-width: 78.125rem;
  width: 100%;
  margin: 0 auto; }

.flex {
  display: flex; }

.header {
  width: 100%;
  margin: 0;
  background-color: var(--primary-bg-color); }
  .header__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 78.125rem;
    width: 100%;
    margin: 0 auto; }
    .header__content__container {
      color: #ffffff;
      width: calc(100% / 2); }
      @media (max-width: 575.5px) {
        .header__content__container {
          width: 100%; } }
      .header__content__container__title {
        color: #ffffff;
        padding: 1.8rem 0 1rem 5rem;
        display: flex;
        justify-content: flex-start;
        align-items: center; }
        @media (max-width: 575.5px) {
          .header__content__container__title {
            padding-left: 1rem; } }
        .header__content__container__title__avatar {
          display: none; }
          .header__content__container__title__avatar img {
            height: 40px;
            width: 40px;
            object-fit: cover;
            object-position: center;
            -o-object-fit: cover;
            -o-object-position: center;
            border-radius: 50%;
            margin-right: 1rem; }
        .header__content__container__title__avatar.show {
          display: block; }
        .header__content__container__title__text a {
          text-decoration: none;
          font-weight: 500;
          line-height: 22px;
          font-size: 22px;
          color: #ffffff; }
      @media (max-width: 575.5px) {
        .header__content__container__navs {
          text-align: right;
          padding: 1.5rem 0.5rem 0; } }
      @media (max-width: 575.5px) {
        .header__content__container__navs__desktop {
          display: none; } }
      .header__content__container__navs__desktop ul {
        text-align: center;
        list-style-type: none;
        padding: 0.5rem 0 0 2rem; }
      .header__content__container__navs__desktop li {
        text-align: center;
        display: inline-block;
        list-style-type: none;
        margin: 0; }
        .header__content__container__navs__desktop li a {
          text-decoration: none;
          font-size: 18px;
          line-height: 22px;
          margin-right: 68px;
          color: #ffffff; }
        .header__content__container__navs__desktop li button {
          cursor: pointer;
          width: 96px;
          height: 36px;
          padding: 0px 8px;
          border: 0;
          box-sizing: border-box;
          box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.8);
          background-color: #000000;
          color: #ffffff;
          font-size: 18px;
          font-family: var(--primary-font);
          line-height: 20px;
          text-transform: uppercase;
          outline: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none; }
      .header__content__container__navs__mobile {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.95);
        z-index: 10;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.2s ease-in; }
        @media (min-width: 575.5px) {
          .header__content__container__navs__mobile {
            display: none; } }
        .header__content__container__navs__mobile .close-btn {
          text-align: right;
          background-color: transparent;
          border: none;
          outline: none;
          cursor: pointer;
          padding: 2rem; }
          @media (min-width: 575.5099px) {
            .header__content__container__navs__mobile .close-btn {
              display: none; } }
          .header__content__container__navs__mobile .close-btn:focus {
            outline: none; }
          .header__content__container__navs__mobile .close-btn img {
            max-width: 120px;
            width: 100%; }
        .header__content__container__navs__mobile ul {
          list-style-type: none;
          padding: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
        .header__content__container__navs__mobile li {
          text-align: center;
          list-style-type: none;
          margin: 1rem 0;
          width: 100%;
          padding: 0 0 1rem;
          border-bottom: 1px solid var(--secondary-bg-color); }
          .header__content__container__navs__mobile li:last-child {
            border-bottom: none;
            padding-bottom: 0; }
          .header__content__container__navs__mobile li a {
            text-decoration: none;
            font-size: 46px;
            line-height: 70px;
            color: #ffffff; }
          .header__content__container__navs__mobile li button {
            cursor: pointer;
            width: 96px;
            height: 36px;
            padding: 0px 8px;
            border: 0;
            box-sizing: border-box;
            box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.8);
            background-color: #000000;
            color: #ffffff;
            font-size: 18px;
            font-family: var(--primary-font);
            line-height: 20px;
            text-transform: uppercase;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            font-size: 46px;
            background-color: transparent;
            width: 100%;
            text-transform: capitalize;
            line-height: 70px;
            margin: 0; }
      .header__content__container__navs__mobile.show {
        visibility: visible;
        opacity: 1; }
      .header__content__container__navs .open-btn {
        text-align: right;
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer; }
        @media (min-width: 575.5099px) {
          .header__content__container__navs .open-btn {
            display: none; } }
        .header__content__container__navs .open-btn:focus {
          outline: none; }
        .header__content__container__navs .open-btn img {
          width: 100%;
          max-width: 25px;
          font-size: 1rem; }

.hero__container {
  background-color: var(--primary-bg-color);
  color: #fff;
  padding: 1rem 5rem 0;
  max-width: 78.125rem;
  width: 100%;
  margin: 0 auto; }
  @media (max-width: 1024px) {
    .hero__container {
      padding: 1rem 5rem 0; } }
  @media (max-width: 575.5px) {
    .hero__container {
      padding: 1rem 1rem 0; } }
  .hero__container__location {
    padding-bottom: 0.2rem; }
    .hero__container__location p {
      font-size: 20px;
      margin: 0;
      margin-top: 16px;
      padding-top: 1.4rem;
      position: relative;
      line-height: 1rem; }
      .hero__container__location p span img {
        margin: 0 6px -4px 0; }
  .hero__container__main__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding-bottom: 0.25rem; }
    @media (max-width: 1024px) {
      .hero__container__main__header {
        flex-direction: column; } }
    .hero__container__main__header h1 {
      color: #ffffff;
      font-size: 64px;
      font-family: var(--primary-font);
      font-weight: 500;
      line-height: 72px;
      padding-right: 1rem;
      text-transform: uppercase; }
      @media (max-width: 1024px) {
        .hero__container__main__header h1 {
          padding-right: 0;
          text-align: center; } }
      @media (max-width: 412px) {
        .hero__container__main__header h1 {
          font-size: 24px;
          line-height: 40px; } }
    .hero__container__main__header p {
      color: #ffffff;
      font-size: 18px;
      font-family: var(--primary-font);
      font-weight: 300;
      line-height: 22px;
      align-self: flex-end;
      text-align: left;
      width: 75%;
      padding-bottom: 2rem; }
      @media (max-width: 1024px) {
        .hero__container__main__header p {
          text-align: center;
          width: 100%; } }
      @media (max-width: 575.5px) {
        .hero__container__main__header p {
          padding-bottom: auto; } }
  .hero__container__main__img img {
    border-radius: 5px;
    display: block;
    max-width: 100%;
    width: 100%;
    margin: 0 auto; }
  .hero__container__main__stats {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 4.7rem 1rem;
    text-align: center; }
    @media (max-width: 768px) {
      .hero__container__main__stats {
        flex-wrap: wrap;
        align-items: baseline;
        justify-content: space-evenly; } }
    @media (max-width: 575.5px) {
      .hero__container__main__stats {
        padding: 4.7rem 0; } }
    .hero__container__main__stats .stat {
      width: 25%; }
      @media (max-width: 768px) {
        .hero__container__main__stats .stat {
          width: calc(100% / 2);
          margin: 1rem auto; } }
      .hero__container__main__stats .stat__copy {
        width: calc(100% / 2);
        margin: 0 auto; }
        .hero__container__main__stats .stat__copy h3 {
          font-size: 48px;
          font-weight: 600;
          text-align: left;
          margin: 0; }
        .hero__container__main__stats .stat__copy p {
          font-size: 20px;
          font-weight: 300;
          text-align: left;
          margin: 0 auto; }

.home__testimonial {
  background-color: var(--secondary-bg-color);
  padding: 1rem 5rem; }
  @media (max-width: 768px) {
    .home__testimonial {
      padding: 1rem 0; } }
  @media (max-width: 575.5px) {
    .home__testimonial {
      padding: 1rem 1rem; } }
  .home__testimonial__card {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 0; }
    @media (max-width: 992px) {
      .home__testimonial__card {
        justify-content: start;
        flex-direction: column-reverse; } }
    .home__testimonial__card__body {
      width: 65%;
      padding: 3rem 0;
      margin: 0; }
      @media (max-width: 992px) {
        .home__testimonial__card__body {
          width: 70%;
          margin: 0 auto;
          text-align: center; } }
      @media (max-width: 575.5px) {
        .home__testimonial__card__body {
          width: 100%; } }
      .home__testimonial__card__body__title {
        color: #ffffff;
        font-size: 24px;
        font-family: var(--primary-font);
        font-weight: 300;
        font-style: italic;
        line-height: 26px;
        margin: 1rem 0 0 0;
        text-align: left; }
      .home__testimonial__card__body__customer {
        color: #ffffff;
        font-size: 18px;
        font-family: var(--primary-font);
        font-weight: 300;
        line-height: 20px;
        padding: 1rem 0;
        margin: 0; }
      .home__testimonial__card__body__copy {
        color: #ffffff;
        font-size: 40px;
        font-family: var(--primary-font);
        font-weight: 500;
        line-height: 44px;
        padding: 1rem 0 0 0;
        margin: 0; }
    .home__testimonial__card__avatar img {
      margin: 0 auto;
      margin-top: 1rem;
      margin-right: 4rem;
      width: 210px;
      height: 210px;
      border-radius: 50%; }
      @media (max-width: 768px) {
        .home__testimonial__card__avatar img {
          margin-right: 0; } }

.services {
  background-color: var(--primary-bg-color);
  padding: 5rem; }
  @media (max-width: 575.5px) {
    .services {
      padding: 5rem 1rem; } }
  .services p.services__title {
    color: #ffffff;
    font-size: 24px;
    font-weight: 300;
    font-style: italic;
    line-height: 26px;
    margin: 0; }
  .services__blocks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1.6rem 0;
    margin: 0;
    position: relative; }
    @media (max-width: 1024) {
      .services__blocks {
        justify-content: space-evenly;
        padding: 0 0; } }
    .services__blocks .service {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      margin: 0; }
      @media (max-width: 1238px) {
        .services__blocks .service {
          min-width: 350px;
          max-width: 350px;
          padding: 0;
          justify-content: left;
          margin: 1rem auto 1rem 0;
          text-align: left; } }
      .services__blocks .service p {
        font-size: 40px;
        color: #fff;
        font-weight: 200;
        line-height: 44px;
        padding: 0 0.8rem;
        margin: 0; }

.featured__gallery {
  background-color: var(--primary-bg-color);
  padding: 0 5rem;
  margin: 0; }
  @media (max-width: 575.5px) {
    .featured__gallery {
      padding: 0 1rem; } }
  .featured__gallery p.featured__gallery__title {
    color: #ffffff;
    font-size: 24px;
    font-family: var(--primary-font);
    font-weight: 300;
    font-style: italic;
    line-height: 26px;
    padding: 3.5rem 0 0 0;
    margin: 0; }
  .featured__gallery__border {
    padding: 4.5rem 1.5rem 0; }
    .featured__gallery__border .border {
      border: 0.5px solid var(--secondary-bg-color); }
  .featured__gallery__btn {
    text-align: center;
    padding: 2rem 0 4.5rem 0; }
    .featured__gallery__btn button {
      cursor: pointer;
      margin: 0.5rem auto;
      width: 266px;
      height: 46px;
      padding: 0px 8px;
      border: 0;
      box-sizing: border-box;
      box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.8);
      background-color: #000000;
      color: #ffffff;
      font-size: 20px;
      font-family: var(--primary-font);
      line-height: 26px;
      text-transform: uppercase;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; }
  .featured__gallery__items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0;
    align-items: baseline;
    margin: 0; }
    @media (max-width: 992px) {
      .featured__gallery__items {
        justify-content: space-evenly; } }
    .featured__gallery__items__item {
      border-radius: 2px;
      margin: 0;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      max-width: 630px;
      width: calc(100% / 2.04);
      height: 304px;
      position: relative; }
      @media (max-width: 992px) {
        .featured__gallery__items__item {
          width: 100%; } }
      @media (max-width: 575.5px) {
        .featured__gallery__items__item {
          height: auto;
          padding-bottom: 1rem; } }
      @media (max-width: 360px) {
        .featured__gallery__items__item {
          padding-bottom: 0;
          margin-bottom: 0; } }
      .featured__gallery__items__item__details {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        margin: 0.5rem 0;
        padding: 0;
        max-height: 3.4rem;
        overflow: hidden; }
        @media (max-width: 575.5px) {
          .featured__gallery__items__item__details {
            flex-direction: column;
            overflow: visible; } }
        .featured__gallery__items__item__details__title {
          color: #ffffff;
          font-size: 24px;
          line-height: 32px;
          cursor: pointer; }
          @media (max-width: 575.5px) {
            .featured__gallery__items__item__details__title {
              width: 100%; }
              .featured__gallery__items__item__details__title p {
                text-align: left; } }
        @media (max-width: 575.5px) {
          .featured__gallery__items__item__details__services {
            width: 100%; } }
        @media (max-width: 360px) {
          .featured__gallery__items__item__details__services {
            display: none; } }
        .featured__gallery__items__item__details__services span {
          cursor: pointer;
          max-width: 100px;
          width: 100%;
          max-height: 32px;
          height: 100%;
          padding: 7px 8px;
          border: 0;
          box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.8);
          background-color: #000000;
          color: #ffffff;
          font-size: 14px;
          font-weight: 300;
          line-height: 18px;
          text-transform: uppercase;
          outline: none;
          margin-right: 8px;
          white-space: nowrap; }
        .featured__gallery__items__item__details__services span:last-child {
          margin-right: 0; }
      .featured__gallery__items__item img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        -o-object-fit: cover;
        -o-object-position: center; }
      .featured__gallery__items__item .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.3);
        opacity: 0;
        transition: opacity 0.3s ease-in; }
        @media (max-width: 575.5px) {
          .featured__gallery__items__item .overlay {
            bottom: 5.4%; } }
        @media (max-width: 430px) {
          .featured__gallery__items__item .overlay {
            bottom: 7.4%; } }
        @media (max-width: 390px) {
          .featured__gallery__items__item .overlay {
            bottom: 8.4%; } }
        @media (max-width: 360px) {
          .featured__gallery__items__item .overlay {
            bottom: 8%; } }
        .featured__gallery__items__item .overlay button {
          cursor: pointer;
          width: 192px;
          height: 46px;
          padding: 0px 8px;
          border: 0;
          box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.8);
          background-color: #000000;
          color: #ffffff;
          font-size: 20px;
          font-family: var(--primary-font);
          line-height: 26px;
          text-transform: uppercase;
          outline: none;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none; }
        .featured__gallery__items__item .overlay button:active {
          outline: 0; }
      .featured__gallery__items__item .overlay:hover {
        opacity: 1; }
      .featured__gallery__items__item .overlay.more-projects {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.3);
        opacity: 0;
        transition: opacity 0.3s ease-in; }
        @media (max-width: 575.5px) {
          .featured__gallery__items__item .overlay.more-projects {
            bottom: 5.4%; } }
        @media (max-width: 430px) {
          .featured__gallery__items__item .overlay.more-projects {
            bottom: 22.4%; } }
        @media (max-width: 414px) {
          .featured__gallery__items__item .overlay.more-projects {
            bottom: 23.4%; } }
        @media (max-width: 390px) {
          .featured__gallery__items__item .overlay.more-projects {
            bottom: 24.4%; } }
        @media (max-width: 360px) {
          .featured__gallery__items__item .overlay.more-projects {
            bottom: 26%; } }
        .featured__gallery__items__item .overlay.more-projects button {
          cursor: pointer;
          width: 192px;
          height: 46px;
          padding: 0px 8px;
          border: 0;
          box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.8);
          background-color: #000000;
          color: #ffffff;
          font-size: 20px;
          font-family: var(--primary-font);
          line-height: 26px;
          text-transform: uppercase;
          outline: none;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none; }
        .featured__gallery__items__item .overlay.more-projects button:active {
          outline: 0; }
      .featured__gallery__items__item .overlay.more-projects:hover {
        opacity: 1; }
    .featured__gallery__items__item.item {
      margin-top: 4.41rem; }
      @media (max-width: 360px) {
        .featured__gallery__items__item.item {
          margin-top: 2.41rem; } }
  .featured__gallery__items.more-projects {
    padding-top: 0.4rem;
    padding-bottom: 7.6rem; }

.footer {
  background-color: var(--secondary-bg-color);
  padding: 3.5rem 0 0.1rem;
  margin: 0; }
  .footer__content {
    max-width: 78.125rem;
    width: 100%;
    margin: 0 auto; }
    .footer__content__contact__container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: baseline;
      margin: 0;
      padding: 0 5rem; }
      @media (max-width: 1024px) {
        .footer__content__contact__container {
          flex-direction: column-reverse; } }
      @media (max-width: 1024px) {
        .footer__content__contact__container {
          justify-content: center;
          align-content: center; } }
      @media (max-width: 768px) {
        .footer__content__contact__container {
          padding: 0; } }
      .footer__content__contact__container__sizing {
        width: 63%;
        margin: 0 auto; }
        @media (min-width: 1024px) {
          .footer__content__contact__container__sizing {
            max-width: 50%; } }
        @media (max-width: 768px) {
          .footer__content__contact__container__sizing {
            width: 75%; } }
      .footer__content__contact__container__form form > p {
        display: none; }
      .footer__content__contact__container__form form.show {
        display: block;
        color: #ffffff;
        font-size: 18px;
        font-family: var(--primary-font);
        font-weight: 300;
        line-height: 22px; }
      .footer__content__contact__container__form__header p {
        color: #ffffff;
        font-size: 24px;
        font-family: var(--primary-font);
        font-weight: 300;
        font-style: italic;
        line-height: 26px;
        text-align: left; }
      .footer__content__contact__container__form input {
        max-width: 504px;
        width: 100%;
        height: 54px;
        padding: 0px 8px;
        border: 1px solid #2e2e2e;
        box-sizing: border-box;
        border-radius: 2px;
        background-color: var(--input-bg-color);
        color: #bebebe;
        font-size: 20px;
        font-family: var(--primary-font);
        font-weight: 300;
        line-height: 22px;
        outline: none;
        display: block;
        margin-bottom: 1rem;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none; }
      .footer__content__contact__container__form button {
        cursor: pointer;
        max-width: 504px;
        width: 100%;
        height: 46px;
        padding: 0px 8px;
        border: 1px solid #000000;
        box-sizing: border-box;
        box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.8);
        background-color: #ffffff;
        color: #000000;
        font-size: 20px;
        font-family: var(--primary-font);
        line-height: 26px;
        text-transform: uppercase;
        outline: none;
        margin: 3rem auto;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none; }
      .footer__content__contact__container__form input[type='submit'] {
        cursor: pointer;
        max-width: 504px;
        width: 100%;
        height: 46px;
        padding: 0px 8px;
        border: 1px solid #000000;
        box-sizing: border-box;
        box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.8);
        background-color: #ffffff;
        color: #000000;
        font-size: 20px;
        font-family: var(--primary-font);
        line-height: 26px;
        text-transform: uppercase;
        outline: none;
        margin: 3rem auto;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none; }
      .footer__content__contact__container__form input.error {
        outline: 1px solid red;
        border: 1px solid red; }
      .footer__content__contact__container__contact {
        color: #ffffff;
        text-align: left;
        padding: 0 1rem; }
        .footer__content__contact__container__contact h2 {
          color: #ffffff;
          font-size: 40px;
          font-family: var(--primary-font);
          font-weight: 500;
          line-height: 44px; }
        .footer__content__contact__container__contact__header p {
          color: #ffffff;
          font-size: 24px;
          font-family: var(--primary-font);
          font-weight: 300;
          font-style: italic;
          line-height: 26px;
          margin: 0;
          text-align: left; }
        .footer__content__contact__container__contact .contact-details {
          padding-bottom: 4.9rem; }
          @media (max-width: 575.5px) {
            .footer__content__contact__container__contact .contact-details {
              padding-bottom: 1rem; } }
          .footer__content__contact__container__contact .contact-details .links {
            padding-bottom: 1rem; }
            @media (max-width: 575.5px) {
              .footer__content__contact__container__contact .contact-details .links {
                padding-bottom: 0; } }
            .footer__content__contact__container__contact .contact-details .links a {
              color: #ffffff;
              font-size: 36px;
              font-family: var(--primary-font);
              font-weight: 500;
              line-height: 44px;
              text-decoration: none;
              display: block;
              font-size: 24px;
              line-height: 34px; }
        .footer__content__contact__container__contact .social-media {
          padding-top: 2.5rem; }
          @media (max-width: 575.5px) {
            .footer__content__contact__container__contact .social-media {
              padding-top: 0.5rem;
              padding-bottom: 1.5rem; } }
          .footer__content__contact__container__contact .social-media img {
            margin-right: 20px;
            cursor: pointer; }
    .footer__content__copyright__container {
      color: #ffffff;
      border-top: 1px solid #404040;
      padding: 0; }
      .footer__content__copyright__container p {
        font-size: 16px;
        font-family: var(--primary-font);
        font-weight: 300;
        line-height: 20px;
        text-align: center; }
      .footer__content__copyright__container button,
      .footer__content__copyright__container a {
        cursor: pointer;
        max-width: 100px;
        width: 100%;
        max-height: 32px;
        height: 100%;
        padding: 7px 8px;
        border: 0;
        box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.8);
        background-color: #000000;
        color: #ffffff;
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
        text-transform: uppercase;
        outline: none;
        margin-right: 8px;
        text-decoration: none;
        text-decoration: none; }

.hero {
  background-color: var(--primary-bg-color);
  padding: 5rem; }
  @media (max-width: 575.5px) {
    .hero {
      padding: 3rem 1rem 1rem; } }
  .hero h1 {
    color: #ffffff;
    font-size: 64px;
    font-family: var(--primary-font);
    font-weight: 500;
    line-height: 72px;
    margin: 0;
    padding: 0;
    width: 65%; }
    @media (max-width: 575.5px) {
      .hero h1 {
        width: 100%;
        margin: 0 auto;
        font-size: 48px; } }

.about__hero {
  background-color: var(--primary-bg-color);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 5.05rem 5.55% 4rem; }
  @media (max-width: 1035px) {
    .about__hero {
      justify-content: center;
      align-items: center;
      flex-direction: column-reverse; } }
  .about__hero__profile {
    width: 50%; }
    @media (max-width: 1035px) {
      .about__hero__profile {
        width: 80%;
        margin: 0 auto;
        text-align: center; } }
    @media (max-width: 768px) {
      .about__hero__profile {
        width: 90%; } }
    .about__hero__profile__bio {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #2e2e2e; }
      .about__hero__profile__bio h1 {
        color: #ffffff;
        font-size: 64px;
        font-family: var(--primary-font);
        font-weight: 500;
        line-height: 72px;
        margin: 0;
        margin-bottom: 0.1rem;
        text-transform: uppercase; }
        @media (max-width: 768px) {
          .about__hero__profile__bio h1 {
            font-size: 38px;
            line-height: 50px; } }
        @media (max-width: 412px) {
          .about__hero__profile__bio h1 {
            font-size: 24px;
            line-height: 40px; } }
      .about__hero__profile__bio p {
        color: #ffffff;
        font-size: 18px;
        font-family: var(--primary-font);
        font-weight: 300;
        line-height: 22px;
        padding-right: 3rem;
        padding-bottom: 1.85rem;
        font-size: 16px;
        line-height: 20px; }
        @media (max-width: 1035px) {
          .about__hero__profile__bio p {
            padding: 1rem; } }
        @media (max-width: 575.5px) {
          .about__hero__profile__bio p {
            padding: 0 1rem 1rem;
            text-align: left; } }
    .about__hero__profile__skills {
      margin-top: 2rem; }
      @media (max-width: 1035px) {
        .about__hero__profile__skills {
          text-align: left; } }
      @media (max-width: 575.5px) {
        .about__hero__profile__skills {
          margin: 2rem auto;
          text-align: center; } }
      .about__hero__profile__skills p {
        padding: 1rem 0 0; }
        @media (max-width: 575.5px) {
          .about__hero__profile__skills p {
            text-align: left; } }
      .about__hero__profile__skills button {
        cursor: pointer;
        max-width: 190px;
        max-height: 46px;
        width: 100%;
        height: 100%;
        padding: 1rem 0.5rem;
        border: 0;
        box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.8);
        background-color: #000000;
        color: #ffffff;
        font-size: 18px;
        font-family: var(--primary-font);
        font-weight: 300;
        line-height: 16px;
        text-transform: uppercase;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin-right: 1rem;
        margin-bottom: 1rem; }
        @media (max-width: 575.5px) {
          .about__hero__profile__skills button {
            margin: 0 auto 1rem; }
            .about__hero__profile__skills button:last-child {
              margin-bottom: 0; } }
      .about__hero__profile__skills p {
        color: #ffffff;
        font-size: 24px;
        font-family: var(--primary-font);
        font-weight: 300;
        font-style: italic;
        line-height: 26px; }
    .about__hero__profile__img {
      width: 50%;
      text-align: right; }
      @media (max-width: 1035px) {
        .about__hero__profile__img {
          width: 65%;
          margin: 0 auto;
          text-align: center; } }
      .about__hero__profile__img img {
        max-width: 28.75rem;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        -o-object-fit: cover;
        -o-object-position: center;
        border-radius: 5px; }
        @media (max-width: 1035px) {
          .about__hero__profile__img img {
            max-width: 80%; } }

.project__hero {
  background-color: var(--primary-bg-color);
  padding: 3.6rem 5rem; }
  @media (max-width: 575.5px) {
    .project__hero {
      padding: 3.6rem 1rem; } }
  .project__hero__back-btn {
    width: 100%;
    padding: 0;
    padding-bottom: 0.3rem;
    margin: 0;
    text-align: left; }
    .project__hero__back-btn img {
      cursor: pointer;
      margin: 0; }
  .project__hero__header h1 {
    color: #ffffff;
    font-size: 64px;
    font-family: var(--primary-font);
    font-weight: 500;
    line-height: 72px;
    line-height: 30px;
    text-transform: uppercase; }
    @media (max-width: 575.5px) {
      .project__hero__header h1 {
        font-size: 24px; } }
  .project__hero__header__languages {
    padding: 0;
    padding-bottom: 3.6rem;
    margin: 0; }
    .project__hero__header__languages span {
      cursor: pointer;
      max-width: 100px;
      width: 100%;
      max-height: 32px;
      height: 100%;
      padding: 7px 8px;
      border: 0;
      box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.8);
      background-color: #000000;
      color: #ffffff;
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
      text-transform: uppercase;
      outline: none;
      margin-right: 8px;
      text-decoration: none;
      padding: 9px 8px; }
  .project__hero__img {
    text-align: center;
    max-height: 400px;
    max-width: 100%;
    margin: 0 auto; }
    .project__hero__img img {
      width: 100%;
      height: 100%;
      max-height: 400px;
      border-radius: 5px;
      object-fit: cover;
      object-position: center;
      -o-object-fit: cover;
      -o-object-position: center; }
  .project__hero__about {
    max-width: 60%;
    padding: 3.7rem 0 2rem 0;
    border-bottom: 1px solid var(--secondary-bg-color); }
    @media (max-width: 575.5px) {
      .project__hero__about {
        max-width: 100%;
        padding: 0.5rem 0 2rem; } }
    .project__hero__about h2 {
      color: #ffffff;
      font-size: 24px;
      font-family: var(--primary-font);
      font-weight: 300;
      font-style: italic;
      line-height: 26px;
      padding: 1.3rem 0 0;
      margin: 0; }
    .project__hero__about p {
      color: #ffffff;
      font-size: 18px;
      font-family: var(--primary-font);
      font-weight: 300;
      line-height: 22px;
      margin: 0;
      padding: 1.08rem 0 1rem; }
      @media (max-width: 575.5px) {
        .project__hero__about p {
          padding: 0.5rem 0 1rem;
          text-align: justify; } }

.project__cards {
  background-color: var(--primary-bg-color);
  padding-bottom: 2.2rem;
  border-bottom: 1px solid var(--secondary-bg-color); }
  .project__cards__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    text-align: center;
    padding: 0 4rem; }
    @media (max-width: 575.5px) {
      .project__cards__container {
        padding: 0 1rem; } }
    .project__cards__container__card {
      width: calc(100% / 2);
      max-width: 668.5px; }
      @media (max-width: 768px) {
        .project__cards__container__card {
          width: 100%; } }
      .project__cards__container__card__img {
        padding: 1.4rem 0.9rem;
        max-width: 628px;
        height: 441.23px; }
        @media (max-width: 1250px) {
          .project__cards__container__card__img {
            height: 360px; } }
        @media (max-width: 992px) {
          .project__cards__container__card__img {
            height: 280px; } }
        @media (max-width: 768px) {
          .project__cards__container__card__img {
            height: 330px; } }
        @media (max-width: 575.5px) {
          .project__cards__container__card__img {
            height: 280px;
            padding: 1.4rem 0.9rem; } }
        .project__cards__container__card__img img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          -o-object-fit: cover;
          -o-object-position: center;
          border-radius: 5px; }
      .project__cards__container__card__summary {
        padding: 2.5rem 1rem;
        text-align: left; }
        @media (max-width: 575.5px) {
          .project__cards__container__card__summary {
            padding: 0.5rem 1rem 2.5rem; } }
        .project__cards__container__card__summary h3 {
          color: #ffffff;
          font-size: 24px;
          font-family: var(--primary-font);
          line-height: 26px;
          margin: 0;
          font-weight: 300;
          padding: 0.8rem 0 1.15rem;
          text-transform: uppercase; }
        .project__cards__container__card__summary p {
          color: #ffffff;
          font-size: 18px;
          font-family: var(--primary-font);
          font-weight: 300;
          line-height: 22px;
          margin: 0; }
  .project__cards__gallery {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    justify-content: space-evenly;
    align-items: center;
    padding: 2.5rem 2.9rem;
    flex: 0 0 calc(100% / 3); }
    @media (max-width: 768px) {
      .project__cards__gallery {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2.5rem 1rem; } }
    .project__cards__gallery__card {
      width: calc(100% / 3);
      margin: 0;
      padding: 0 2rem; }
      @media (max-width: 768px) {
        .project__cards__gallery__card {
          width: 100%;
          margin-bottom: 1.5rem;
          padding: 0; }
          .project__cards__gallery__card:last-child {
            margin-bottom: 0; } }
      .project__cards__gallery__card img {
        max-width: 410px;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        margin: 0;
        padding: 0;
        border-radius: 5px;
        object-fit: cover;
        object-position: center;
        -o-object-fit: cover;
        -o-object-position: center; }

.project__video {
  background-color: var(--primary-bg-color);
  padding: 1.7rem 1rem; }
  .project__video__header {
    padding: 2rem 4rem 0;
    text-align: right; }
    @media (max-width: 575.5px) {
      .project__video__header {
        padding: 0 1rem; } }
    .project__video__header h3 {
      color: #ffffff;
      font-size: 24px;
      font-family: var(--primary-font);
      line-height: 26px;
      text-transform: uppercase;
      font-weight: 300; }
  .project__video__player {
    margin: 0;
    padding: 0 4rem 4.1rem;
    text-align: center;
    position: relative; }
    @media (max-width: 575.5px) {
      .project__video__player {
        padding: 0 0 1rem; } }
    .project__video__player video {
      max-width: 1280px;
      max-height: 612px;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      padding: 0;
      object-fit: cover;
      object-position: center;
      -o-object-fit: cover;
      -o-object-position: center;
      border-radius: 5px; }
    .project__video__player video::-webkit-media-controls-start-playback-button {
      display: none; }
  .project__video__play__pause__container {
    position: absolute;
    top: 0;
    left: 4rem;
    right: 4rem;
    bottom: 4.3rem;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease-in; }
    @media (max-width: 575.5px) {
      .project__video__play__pause__container {
        left: 0;
        right: 0;
        bottom: 1.3rem; } }
    .project__video__play__pause__container:hover {
      opacity: 1; }
    .project__video__play__pause__container__playBtn {
      cursor: pointer;
      background-color: transparent;
      border: none;
      outline: 0;
      max-width: 96px;
      max-height: 96px;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
      .project__video__play__pause__container__playBtn:active {
        outline: 0; }
      .project__video__play__pause__container__playBtn:focus {
        outline: 0; }
    .project__video__play__pause__container__pauseBtn {
      cursor: pointer;
      background-color: transparent;
      border: none;
      outline: 0;
      max-width: 96px;
      max-height: 96px;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: none; }
      .project__video__play__pause__container__pauseBtn:active {
        outline: 0; }
      .project__video__play__pause__container__pauseBtn:focus {
        outline: 0; }

.new__project__form-container {
  max-width: 78.125rem;
  width: 100%;
  margin: 0 auto; }
  .new__project__form-container__form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    max-width: 1024px;
    width: 100%;
    margin: 0 auto; }
    @media (max-width: 768px) {
      .new__project__form-container__form {
        flex-direction: column;
        flex-wrap: wrap; } }
    .new__project__form-container__form__input-group {
      width: calc(100% / 2);
      max-width: 600px;
      margin: 0 auto;
      flex: calc(100% / 2);
      padding: 0 2rem; }
      @media (max-width: 768px) {
        .new__project__form-container__form__input-group {
          max-width: 95%;
          width: 100%;
          flex: 100%;
          margin: 0 auto; } }
      .new__project__form-container__form__input-group h2,
      .new__project__form-container__form__input-group h3 {
        color: #ffffff;
        font-family: var(--primary-font);
        font-weight: 500;
        font-style: italic;
        line-height: 26px;
        text-transform: capitalize; }
      .new__project__form-container__form__input-group label {
        display: block;
        color: #ffffff;
        font-family: var(--primary-font);
        font-weight: 300;
        font-style: italic;
        line-height: 26px; }
      .new__project__form-container__form__input-group input {
        max-width: 504px;
        width: 100%;
        height: 54px;
        padding: 0px 8px;
        border: 1px solid #2e2e2e;
        box-sizing: border-box;
        border-radius: 2px;
        background-color: var(--input-bg-color);
        color: #bebebe;
        font-size: 20px;
        font-family: var(--primary-font);
        font-weight: 300;
        line-height: 22px;
        outline: none;
        display: block;
        margin-bottom: 1rem;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none; }
      .new__project__form-container__form__input-group textarea {
        max-width: 504px;
        width: 100%;
        height: 54px;
        padding: 0px 8px;
        border: 1px solid #2e2e2e;
        box-sizing: border-box;
        border-radius: 2px;
        background-color: var(--input-bg-color);
        color: #bebebe;
        font-size: 20px;
        font-family: var(--primary-font);
        font-weight: 300;
        line-height: 22px;
        outline: none;
        display: block;
        margin-bottom: 1rem;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        height: 150px; }
      .new__project__form-container__form__input-group button {
        cursor: pointer;
        max-width: 504px;
        width: 100%;
        height: 46px;
        padding: 0px 8px;
        border: 1px solid #000000;
        box-sizing: border-box;
        box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.8);
        background-color: #ffffff;
        color: #000000;
        font-size: 20px;
        font-family: var(--primary-font);
        line-height: 26px;
        text-transform: uppercase;
        outline: none;
        margin: 3rem auto;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none; }
      .new__project__form-container__form__input-group__languages label {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: 95%; }
        .new__project__form-container__form__input-group__languages label img {
          max-height: 30px;
          max-width: 30px;
          height: 100%;
          width: 100%;
          cursor: pointer;
          object-fit: cover;
          object-position: center;
          -o-object-fit: cover;
          -o-object-position: center; }

.profile__header {
  width: 100%;
  padding: 0 0 0.2rem; }
  .profile__header__img {
    width: 100%;
    height: 324px;
    padding: 2.83rem 0; }
    .profile__header__img img {
      width: 100%;
      height: 324px;
      object-fit: cover;
      object-position: center;
      -o-object-fit: cover;
      -o-object-position: center; }
  .profile__header__details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2rem 0; }
    @media (max-width: 575.5px) {
      .profile__header__details {
        flex-direction: column; } }
    .profile__header__details__user {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center; }
      @media (max-width: 575.5px) {
        .profile__header__details__user {
          flex-direction: column;
          height: 216px; } }
      .profile__header__details__user__avatar img {
        height: 115px;
        width: 115px;
        border-radius: 50%;
        margin-right: 1rem; }
      .profile__header__details__user__name h2 {
        color: #ffffff;
        font-size: 36px;
        font-family: var(--primary-font);
        font-weight: 500;
        line-height: 64px;
        line-height: 10px; }
      .profile__header__details__user__name p {
        color: #ffffff;
        font-size: 18px;
        font-family: var(--primary-font);
        font-weight: 300;
        line-height: 22px;
        padding: 0;
        margin: 0;
        line-height: 0; }
    .profile__header__details__btn-group {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: baseline;
      padding: 0.2rem 0 0;
      margin: 0; }
      .profile__header__details__btn-group button {
        cursor: pointer;
        margin: 0.5rem auto;
        width: 266px;
        height: 46px;
        padding: 0px 8px;
        border: 0;
        box-sizing: border-box;
        box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.8);
        background-color: #000000;
        color: #ffffff;
        font-size: 20px;
        font-family: var(--primary-font);
        line-height: 26px;
        text-transform: uppercase;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        max-width: 164px;
        margin: 0;
        margin-right: 1rem; }
  .profile__header__links {
    padding: 0 2.5rem; }
    .profile__header__links ul {
      list-style-type: none;
      padding: 0 0 1rem;
      border-bottom: 1px solid var(--secondary-bg-color); }
    .profile__header__links li {
      color: #ffffff;
      font-size: 18px;
      font-family: var(--primary-font);
      font-weight: 300;
      line-height: 22px;
      display: inline-block;
      margin-right: 1rem;
      line-height: 0; }
      .profile__header__links li:last-child {
        margin-right: 0; }

.profile-projects h2 {
  color: #ffffff;
  font-size: 24px;
  font-family: var(--primary-font);
  font-weight: 300;
  font-style: italic;
  line-height: 26px; }

.profile-projects__container {
  padding: 0 0 3rem; }
  .profile-projects__container__project {
    background-color: var(--secondary-bg-color);
    padding: 1.5rem;
    margin: 0 auto;
    margin-bottom: 2rem; }
    .profile-projects__container__project h3 {
      color: #ffffff;
      font-size: 36px;
      font-family: var(--primary-font);
      font-weight: 500;
      line-height: 64px;
      margin: 0;
      padding: 0; }
    .profile-projects__container__project p {
      color: #ffffff;
      font-family: var(--primary-font);
      font-weight: 300;
      font-style: italic;
      line-height: 26px;
      margin: 0;
      padding-bottom: 2rem; }
    .profile-projects__container__project:last-child {
      margin-bottom: auto; }
    .profile-projects__container__project__img-group {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start; }
      .profile-projects__container__project__img-group__portrait {
        max-height: 400px;
        height: 100%;
        width: 100%;
        padding: 0 0.5rem; }
        @media (max-width: 575.5px) {
          .profile-projects__container__project__img-group__portrait {
            margin: 0 auto;
            text-align: center; } }
        .profile-projects__container__project__img-group__portrait img {
          max-width: 392px;
          max-height: 400px;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          -o-object-fit: cover;
          -o-object-position: center; }
      .profile-projects__container__project__img-group__landscape {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        height: 400px;
        width: 100%;
        padding: 0 0.5rem; }
        @media (max-width: 575.5px) {
          .profile-projects__container__project__img-group__landscape {
            display: none; } }
        .profile-projects__container__project__img-group__landscape__img {
          max-width: 409px;
          max-height: 188px; }
          .profile-projects__container__project__img-group__landscape__img img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            -o-object-fit: cover;
            -o-object-position: center; }

.profile-body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: baseline;
  width: 100%;
  padding: 0 2.5rem; }
  @media (max-width: 768px) {
    .profile-body {
      flex-direction: column; } }
  @media (max-width: 575.5px) {
    .profile-body {
      padding: 0 1rem; } }
  .profile-body__left-container {
    width: calc(100% / 3); }
  .profile-body__right-container {
    width: calc((100% / 3) * 2); }
  @media (max-width: 768px) {
    .profile-body__left-container, .profile-body__right-container {
      width: 100%; } }

.edit-profile h2 {
  color: #ffffff;
  font-size: 24px;
  font-family: var(--primary-font);
  font-weight: 300;
  font-style: italic;
  line-height: 26px; }

.edit-profile__btn-group {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-shrink: 1;
  flex-grow: 1;
  justify-content: space-around;
  padding-right: 1rem; }
  @media (max-width: 768px) {
    .edit-profile__btn-group {
      padding-bottom: 2rem;
      padding-right: 0; } }
  .edit-profile__btn-group a {
    cursor: pointer;
    margin: 0.5rem auto;
    max-width: 266px;
    width: 100%;
    height: 46px;
    padding: 0px 8px;
    border: 0;
    box-sizing: border-box;
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.8);
    background-color: #000000;
    color: #ffffff;
    font-size: 20px;
    font-family: var(--primary-font);
    line-height: 26px;
    text-transform: uppercase;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    text-align: center;
    align-items: center;
    line-height: 42.5px;
    text-decoration: none;
    text-transform: uppercase;
    margin-bottom: 1rem;
    margin-left: 0; }
    @media (max-width: 768px) {
      .edit-profile__btn-group a {
        margin: 1rem auto;
        width: 80%; } }
    .edit-profile__btn-group a:last-child {
      margin-bottom: 0; }

.loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  background-color: var(--primary-bg-color);
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
  .loading__shape {
    width: 60vw;
    height: 60vh;
    border-radius: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
    .loading__shape h1 {
      color: #ffffff;
      font-size: 64px;
      font-family: var(--primary-font);
      font-weight: 500;
      line-height: 72px;
      font-size: 64px; }
